<template>
  <v-container tag="article">
    <section class="py-1">
      <h1 class="headline" id="terms">Terms of Use</h1>
      <p class="mt-2 mb-1">
        The following rules governs our relationship with users and others who interacts with the products we provide, which we call "AmborJo Services" or simply "Services".
        By using our website, you agree to these terms.
      </p>
      <ol class="mt-2 mb-1">
        <li>You will not use any of the AmborJo Services to do any activity that is unlawful or malicious.</li>
        <li>You will not upload viruses or other malicious code.</li>
        <li>You will not carry out any action that could disable, overburden, or otherwise prevent AmborJo Services from functioning or displaying properly.</li>
        <li>You will not provide information that is intended to mislead us or other users.</li>
        <li>You will not post unauthorized content including, but (not limited to) spam, hate speech, excessive violence, or pornography to the website.</li>
        <li>You will not bully, intimidate, or otherwise harass any user.</li>
        <li>You will not encourage or facilitate any violation of these rules.</li>
      </ol>
      <p class="mt-2 mb-1">We also require you to accept the following statement(s):</p>
      <ol class="mt-1">
        <li>We do our best in making sure that the information we provide in our services as accurate as possible. However, we provide no guarantee that the information is either accurate or up-to-date.</li>
        <li>
          We try to keep our services available, safe to use, and functioning properly, but you use it at your own risk.
          We provide AmborJo Services without any guarantee that it will always function safely and securely without errors, delays, bugs, or disruptions.
        </li>
        <li>We retain the rights to, at our discretion, ban users who violate these rules.</li>
        <li>We retain the rights to make changes to the Terms of Use and Privacy Policy at any moment.</li>
      </ol>
    </section>
    <section class="py-1">
      <h1 class="headline" id="privacy">Privacy Policy</h1>
      <p class="mt-2 mb-1">
        As a business, we rely on gathering, storing, and processing information so that we can provide our services to our customers such as you, our users.
        The following text explains how we gather, store, use, and share your information.
      </p>
      <ol class="mt-1">
        <li>
          <h2 class="title">What information do we collect?</h2>
          <p class="mt-2 mb-1">
            We collect information you explicitly provide to us (such as your reviews or comments).
            We collect information about the addresses of pages that you visit within the AmborJo website.
            We collect information that is provided by social network or other services that you use to login (for example, Facebook or Google). We collect your name and other basic profile information.
          </p>
          <p class="mt-2 mb-1">
            And lastly we use Google Analytics to gather non-personally identifiable data of our visitors, and Google AdSense to display advertisements for revenue.
            Learn more about <a href="http://www.google.com/policies/privacy/partners/">How Google uses data when you use our website</a>.
          </p>
        </li>
        <li>
          <h2 class="title">How do we use the information?</h2>
          <p class="mt-2 mb-1">
              We use the information to deliver our services, personalize content, and find ways to improve our services and fix problems.
              We use the information to communicate with you about our services, and respond to you when you contact us.
          </p>
        </li>
        <li>
          <h2 class="title">How do we share the information?</h2>
          <p class="mt-2 mb-1">
            Public information such as your name, profile picture, and reviews that you leave on our articles is visible to the public.
            We may share your information that we have when legally requested by law enforcement or government (for example, search warrant or court order).
            If, in the future, the ownership or control of our Services changes, we may transfer the information to the new owner.
          </p>
        </li>
      </ol>
    </section>
    <section class="py-1">
      <h1 class="headline" id="changes">Changes to This Information</h1>
      <p class="mt-2 mb-1">We will notify you before we make any changes to the Terms of Use and Privacy Policy so that you can review them before continuing to use our Services.</p>
    </section>
    <!-- <section class="py-1">
      <h1 class="headline" id="contact">How to Contact AmborJo with questions</h1>
      <p class="mt-2 mb-1">You may visit our <a href="/contact">Contact page</a> and use the e-mail address provided to reach us.</p>
    </section> -->
  </v-container>
</template>

<script>
export default {
  beforeCreate () {
    document.title = 'Terms & Conditions and Privacy Policy'
  }
}
</script>
